<template>
    <section id="dashboard-ecommerce">
      <div class="sec1 d-flex justify-content-between pb-1">
        <h1 class="text-black">Ranking{{ search}}</h1>
        <div>
  
          <button type="button" 
            v-b-tooltip.hover.v-primary
            @click="exportToCSV"
            title="Export CSV"
            variant="outline-primary"
            class="btn notofication-btn mr-1">
            <img src="../../../assets/images/client/export.svg" alt="Snow" />
          </button>
        </div>
      </div>
  
      <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="reportedCouponsData.length == 0">
      <img style="width:13cm; height:13cm" :src="host + '/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <draggable
          v-model="reportedCouponsData"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline"
        >
          <b-card
            no-body
            class="card-revenue-budget dash-total-card mb-2 drag-el"
            v-for="(item,index) in reportedCouponsData"
            :key="index"
          >
            <b-row class="p-1">
              <b-col md="2" class="d-flex">
                  <div class="ml-1  mr-1">
                    <div class="font-weight-bolder text-black mb-half">
                      ID
                    </div>
                    {{ item.id  }}
                </div>
              </b-col>
              <b-col md="2" class="d-flex align-items-center">
                <div>
                    <div class="font-weight-bolder text-black mb-half">
                      Name
                    </div>
                    <div style="max-width: 15cm;">{{ item.user_name}}</div>
                  </div>
              </b-col>
              <b-col  md="2 " class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Installs in UAE</div>
                  <div class=" text-black">
                      {{ item.android_install_uae + item.ios_install_uae }}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Install outside</div>
                  <div class=" text-black">
                      {{ item.android_install+ item.ios_install - item.android_install_uae - item.ios_install_uae }}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Android Installs</div>
                  <div class=" text-black">
                      {{ item.android_install}}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">IOS Installs</div>
                  <div class=" text-black">
                      {{ item.ios_install   }}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Android Installs in UAE</div>
                  <div class=" text-black">
                      {{ item.android_install_uae}}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">IOS Installs in UAE</div>
                  <div class=" text-black">
                      {{ item.ios_install_uae}}
                  </div>
                </div>
              </b-col>
              <b-col  md="2" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Rank</div>
                  <div class=" text-black">
                      {{ item.rank}}
                  </div>
                </div>
              </b-col>
              <b-col  md="3" class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder text-black mb-half">Total Registration + install form UAE</div>
                  <div class=" text-black">
                      {{ item.referral_points}}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !reportedCouponsData.length }"></div>
      </div>
  
      <button v-if="!isLoading && reportedCouponsData.length"  type="button" class=" w-auto btn slide-btn">
        <div class="d-flex justify-content-around align-items-center">
          
          <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
            paginationLength : paginationEnd }} of {{ paginationLength }}</p>
          <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
          <input v-model="pageNo" class="form-control paginationInput" :style="'width:' + ((pageNo.toString().length*10)) + 'px'" @input="pagechange" type="number"/>
          <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
          
        </div>
      </button>
    </section>
  </template>
  
  <script>
  import draggable from "vuedraggable";
  import { BImg } from 'bootstrap-vue'
  import json2csv from 'json2csv';
  
  import vSelect from 'vue-select';
  // eslint-disable-next-line
  import 'quill/dist/quill.core.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
  // eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'
  import { mapState } from 'vuex';
  export default {
    components: {
      draggable,
      vSelect,
      quillEditor,BImg
    },
    data() {
      return {
        mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
        data: {},
        filter:'All',
        selectAllLabel:'Select All',
        baseURL: process.env.VUE_APP_BASE_URL,
        host:window.location.origin,
        AdPlacemnetOption:[],
        reportedCouponsData: [],
        reportedCouponsData1: [],
        popoverShow: false,
        edit:false,
        popoverShow1: false,
        pageNo:1,
        paginationStart:0,
        paginationEnd:10,
        paginationLength:0,
        clientNameEng:'',
        isLoading:true,
        clientNameEArb: '',
        category: [],
        category_ids: [],
        descriptionEng: '',
        descriptionArb: '',
        status: '',
        sequence: '',
        checkBoxIds:{},
        websiteUrl: '',
        imageUrl: '',
        selectAll:false,
        keywordEng: '',
        popoverShow3: {},
        keywordArb: '',
        showItem:{}
      };
    },
     watch: {
      'sidebar': {
        handler: 'checkIsValid',
        immediate: true, 
      },
    '$store.state.application': {
      handler: 'checkIsValid',
      immediate: true,
    },
    },
    mounted(){
    },
      computed:{
        ...mapState(['sidebar']),
        search() {
           let search = this.$store.state.searchData
            if(this.$store.state.application.id){
            this.getSearch(search);
            }
          },
      },
    methods: {
      checkIsValid() {
        if (this.sidebar) {
          let is_valid = this.sidebar.some(data => data.name == 'Ranking');
          if (!is_valid) {
            this.$router.push('/');
          }
        }
      },
       getSearch: _.debounce(function (search) {
        this.pageNo = 1
        this.paginationStart = 0
        this.paginationEnd = 10
        // if(search){
        this.isLoading = true
        this.$http.post(`${this.baseURL}/ranking/search`,{search:search,pageno:this.pageNo,app_id:this.$store.state.application.id},
                {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials:true
              }).then((res)=>{  
            console.log(res,'res')
              this.reportedCouponsData = res.data.data
              this.paginationLength = res.data.totaldata  
              this.reportedCouponsData1 = res.data.data
              this.isLoading = false
              })
            // }
      }, 1000),
      // getAdPlacemnetToName(id){
      //   let data = this.AdPlacemnetOption.filter(item => item.id == id)
      //   return (data.length > 0 ? data[0].name : '')
      // },
      exportToCSV() {
        const csvData = json2csv.parse(this.reportedCouponsData)
        ;
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        link.download = 'Ranking.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      closeModal(){
          this.$refs['modal'].hide()
      },
      handelChangeFilter(){
        console.log(this.filter)
        if(this.filter == "All"){
          this.reportedCouponsData = this.reportedCouponsData1
        }
        else if(this.filter == "Active"){
          let data = []
          data = this.reportedCouponsData1.filter(item => item.is_active == 1)
          this.reportedCouponsData = data
        }
        else if(this.filter == "Inactive"){
          let data = []
          data = this.reportedCouponsData1.filter(item => item.is_active == 0)
          this.reportedCouponsData = data
        }
      },
      onPopovcerOpen3(id) {
        this.popoverShow3[id] = this.popoverShow3[id] == undefined ? false : this.popoverShow3[id];
        this.popoverShow3[id] = this.popoverShow3[id] == true ? false : true;
        this.$forceUpdate()
      }, 
      checkURL(url) {
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return pattern.test(url ? url.replace(/\s/g,'%20') : url);
      },
      pagechange(){
        if(this.pageNo>(this.paginationLength/10)){
          this.pageNo = parseInt(this.paginationLength/10)
        }
        if(this.pageNo == 0){
          this.pageNo = 1
        }
          this.paginationStart = (this.pageNo*10)-10
          this.paginationEnd = (this.pageNo*10)
        let search = this.$store.state.searchData
            if (search) {
              this.searchPaginationHandelClick(search)
            } else {
              this.normalPaginationHandelClick(search)
            }
      },
      searchPaginationHandelClick: _.debounce(function (search) {
        this.isLoading = true
          this.$http.post( `${this.baseURL}/ranking/search`,{search:search,pageno:this.pageNo,app_id:this.$store.state.application.id}).then((res)=>{
              console.log(res.data.data,"res-=-=")
              this.reportedCouponsData=res.data.data
              // this.pageNo=res.data.page
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
              this.paginationLength = res.data.totaldata
              this.reportedCouponsData1=res.data.data
              this.isLoading = false
            })
        }, 1000),
        normalPaginationHandelClick: _.debounce(function () {
          this.isLoading = true
          this.$http.post( `${this.baseURL}/ranking/index`,{pageno:this.pageNo,app_id:this.$store.state.application.id}).then((res)=>{
              console.log(res.data.data,"res-=-=")
              this.reportedCouponsData=res.data.data
              // this.pageNo=res.data.page
          this.selectAllLabel = 'Select All'
          this.checkBoxIds = {}
              this.paginationLength = res.data.totaldata
              this.reportedCouponsData1=res.data.data
              this.isLoading = false
            })
        }, 1000),
      paginationHandelClick(value){
        let search = this.$store.state.searchData
        if(value == 'back'){
            if( this.paginationStart > 1){
              this.pageNo--
              this.paginationStart = this.paginationStart - 10
              this.paginationEnd = this.paginationEnd - 10
             if(search){
              this.searchPaginationHandelClick(search,value)
             }else{
              this.normalPaginationHandelClick(search,value)
             }
            }
        }
        else if(value == 'next'){
            if( this.paginationEnd < this.paginationLength){
              this.pageNo++
              this.paginationStart = this.paginationStart + 10
              this.paginationEnd = this.paginationEnd + 10
             if(search){
              this.searchPaginationHandelClick(search,value)
             }else{
              this.normalPaginationHandelClick(search,value)
             }
            }
        }
      },
      HandelClickMultipleDelete(){
            let arr = []
           Object.keys(this.checkBoxIds).map((key) => {
              if( this.checkBoxIds[key] == true){
              arr.push(key)
              }
            });
          this.$http.post(`${this.baseURL}/ranking/multi-delete`,{ids:arr},
                {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials:true
              }).then((res)=>{
                this.$swal({
                  title: "Deleted",
                  text: res.data.message
                    ? `${res.data.message}`
                    : res.data.success
                    ? `${res.data.success}`
                    : "Deleted",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.getClientStore()
        this.onPopovcerOpen3(id)
        this.popoverShow3[id] = false
        this.checkBoxIds = {}
        this.getClientStore()
        this.$forceUpdate()
              })
      },
      handelDelete(id){
        this.$http.post(`${this.baseURL}/ranking/delete`,{id:id},
                {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials:true
              }).then((res)=>{
                this.$swal({
                  title: "Deleted",
                  text: res.data.message
                    ? `${res.data.message}`
                    : res.data.success
                    ? `${res.data.success}`
                    : "Deleted",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.getClientStore()
        this.onPopovcerOpen3(id)
        this.checkBoxIds = {}
        this.popoverShow3[id] = false
        this.getClientStore()
        this.$forceUpdate()
              })
      },
      selectAllClick(){
         let data  = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
         console.log(data)
            if(data.length == 10){
              this.selectAllLabel = 'Select All'
              this.checkBoxIds = {}
           }else{
              this.selectAllLabel = 'Deselect All'
            // console.log('enter',this.paginationStart,this.paginationEnd,this.reportedCouponsData,this.checkBoxIds)
            for(let x = this.paginationStart; x < this.paginationEnd; x++){
              this.checkBoxIds[this.reportedCouponsData[x].id] = true
            }
           }
           this.$forceUpdate()
      },
      CheckBoxHandelClick(id){
        // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
        // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
        let data  = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
            if(data.length == 10){
              this.selectAllLabel = 'Deselect All'
            }else{
              this.selectAllLabel = 'Select All'
            }
            this.$forceUpdate()
      },
      onPopovcerOpen() {
        this.popoverShow = !this.popoverShow;
      },
      onPopovcerOpen1() {
        this.popoverShow1 = !this.popoverShow1;
      },
      async getClientStore(){
        this.isLoading = true
        this.$http.post( `${this.baseURL}/ranking/index`,{pageno:this.pageNo,app_id:this.$store.state.application.id}).then((res)=>{
              console.log(res.data.data,"res-=-=")
              this.reportedCouponsData = res.data.data
              this.paginationLength = res.data.totaldata  
              this.reportedCouponsData1 = res.data.data
              this.isLoading = false
            })
        // this.$http.post(`${this.baseURL}/ad-placement/index`).then((res)=>{
        //       console.log(res.data.data,"res-=-=")
        //       this.AdPlacemnetOption = res.data.data
        //       this.checkBoxIds = {}
        //     })
      
        // let data = await getreportedCouponsData();
        // console.log(data,"data=====");
      }
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  // .draggable-task-handle {
  //     transform: translateY(-50%);
  //     visibility:visible;
  //     cursor: move;
  
  //     .todo-task-list .todo-item:hover & {
  //       visibility: hidden;
  //     }
  // }
  
  .success-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #0ac947;
    border: 1px solid #0ac947;
    border-radius: 4px;
  }
  .danger-btn {
    width: 58px;
    padding: 3px 5px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #c90a0a;
    border: 1px solid #c90a0a;
    border-radius: 4px;
  }
  .mb-half {
    margin-bottom: 5px;
  }
  .notofication-btn {
    background-color: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    padding: 12px 12px;
  }
  .slide-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    padding: 15 px;
    margin: 0;
    width: 200px;
    float: right;
  }
  .see-btn {
    background: #ffffff;
    box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
    border-radius: 14px;
    border-radius: 16px;
    width: 125px;
  }
  .bs-popover-left {
    border-radius: 14px !important;
    border: transparent;
    min-width: 140px !important;
    max-width: 350px !important;
  }
  .bs-popover-left .popover-body {
    display: flex;
    justify-content: space-between;
    border: none;
  }
  .bs-popover-bottom {
    background-color: #ffffff;
    // height: 190px;
    border: transparent;
    border: none;
    border-radius: 14px;
  }
  .popover-body {
    border: none !important;
  }
  .popover.bs-popover-bottom .arrow:after {
    border-bottom-color: #ffffff;
  }
  .vs-select__options__content {
    background-color: #ffffff !important;
  }
  .pop-btn {
    padding: 0 !important;
  }
  .modal-dialog {
  
  }
  .modal-body {
     padding: 0px 40px 40px 40px !important
  }
  .form-control {
    border-radius: 14px;
  }
  .modal-content {
    border-radius: 24px;
  }
  .modal-header {
    border-radius: 24px;
    height: fit-content !important;
  }
  textarea.form-control {
    min-height: 150px !important;
  }
  .popover1-body {
    padding: 0;
  }
  .modal-footer {
    display: none;
  }
  .modal-footer1 {
    display: flex;
    float: right;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.8rem 1.4rem;
  }
  </style>
  